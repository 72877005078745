import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_REQUEST,
  SET_USER,
  UPDATE_PROFILE_NAME,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_REQUEST
} from 'action_types/auth';
import * as errorTypes from 'action_types/error';

const initialState = {
  isAuthenticated: false,
  user: {
    id: '',
    name: '',
    full_name: '',
    org_name: '',
    email: '',
    organisation: '',
    role: '',
    scope: '',
    mfa_status: false,
    avatar_color: '',
    asset_groups: []
  },
  loading: false,
  registered: false,
  role: '',
  creditDistribution: [],
  darkTheme: JSON.parse(localStorage.getItem('darkTheme')),
  windowSize: window.innerWidth,
  avatarColor: '',
  refresh: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_USER:
      return {
        ...state,
        isAuthenticated: action.payload.role ? true : false,
        role: action.payload.role ? action.payload.role : ''
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
        role: action.payload.role ? action.payload.role : '',
        loading: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isAuthenticated: !!action.payload.role || !!action.payload.id,
        role: action.user.role ? action.user.role : '',
        loading: false
      };
    case 'AVATAR_COLOR_SUCCESS':
      return {
        ...state,
        avatarColor: action.payload
      };
    case 'UPDATE_ROLE':
      return {
        ...state,
        role: action.role
      };
    case 'UPDATE_AVATAR_COLOR_SUCCESS':
      return {
        ...state,
        avatarColor: action.payload
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user
        },
        isAuthenticated: !!action.payload.token,
        role: action.payload.role ? action.payload.role : '',
        loading: false
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
        user: {
          id: '',
          name: '',
          full_name: '',
          org_name: '',
          email: '',
          organisation: '',
          role: '',
          scope: '',
          mfa_status: false,
          avatar_color: '',
          asset_groups: []
        },
        role: '',
        isAuthenticated: false,
        darkTheme: JSON.parse(localStorage.getItem('darkTheme'))
      };
    case UPDATE_PROFILE_NAME:
      return {
        ...state,
        user: { ...state.user, name: action.userName }
      };
    case errorTypes.CATCH_ERROR:
    case 'INVITE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        refresh: false
      };
    case 'SAVE_CREDIT_DISTRIBUTION':
      return {
        ...state,
        creditDistribution: action.credits
      };
    case 'CHANGE_THEME_TO_DARK':
      return {
        ...state,
        darkTheme: true
      };
    case 'CHANGE_THEME_TO_LIGHT':
      return {
        ...state,
        darkTheme: false
      };
    case 'CHANGE_WINDOW_SIZE':
      return {
        ...state,
        windowSize: action.size
      };
    case 'REFRESH_PAGE':
      return {
        ...state,
        refresh: action.refresh
      };
    case 'LOGIN_USER':
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      };
    default:
      return state;
  }
}
